import Swal from 'sweetalert2'
import _ from 'lodash'

const swalBtnClass = 'v-btn v-btn--has-bg theme--light v-size--default mx-4'
const swal = Swal.mixin({
  target: '#app',
  customClass: {
    confirmButton: `${swalBtnClass} primary`,
    cancelButton: `${swalBtnClass} error`,
    input: 'swal2-input-popup-borderless'
  },
  buttonsStyling: false
})

export const templates = {
  success: {
    icon: 'success',
    title: 'alert.success.title',
    text: 'alert.success.text'
  },
  error: {
    icon: 'error',
    title: 'alert.error.title',
    text: 'alert.error.text'
  }
}

// export const swalAlert = (t, status, options = {}) => {
//   const alert = {
//     success: {
//       icon: templates.success.icon,
//       title: t(templates.success.title),
//       text: t(templates.success.text)
//     },
//     error: {
//       icon: templates.error.icon,
//       title: t(templates.error.title),
//       text: t(templates.error.text)
//     },
//     custom: options
//   }
//
//   if (alert[status]) {
//     return swal.fire(alert[status])
//   }
// }

export const swalWarn = (t, text, title) => {
  swal.fire({
    icon: 'warning',
    title: title,
    text: text
  })
}
export const swalError = (t, text, title) => {
  swal.fire({
    icon: 'error',
    title: title ?? t(templates.error.title),
    text: text ?? t(templates.error.text)
  })
}

export const swalValidationError = (t, data) => {
  let txt = ''
  _.forEach(data, (value, key) => {
    if (_.isArray(value)) {
      value.forEach((msg) => {
        txt += `${key}: ${msg}</br>`
      })
    }
  })
  swal.fire({
    icon: 'error',
    title: t('alert.validationError'),
    html: txt
  })
}

export const swalRequestErrorHandler = (t, error) => {
  const { status, data } = error
  if (status === 400 && data && Object.values(data).length) {
    swalValidationError(t, data)
  } else {
    swalError(t)
  }
}

export const swalSuccess = (t, text) => {
  swal.fire({
    icon: 'success',
    title: t('alert.success.title'),
    text: text ?? t('alert.success.text')
  })
}

export const swalConfirmDelete = (t, text) => {
  return swal.fire({
    icon: 'warning',
    title: t('alert.submitDelete.title'),
    text: text ?? t('alert.submitDelete.text'),
    showCancelButton: true,
    confirmButtonText: t('alert.submitDelete.confirmButtonText')
  })
}

export default swal
