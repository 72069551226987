<template>
  <div class="app-snackbar">
    <!-- This is now just a container, actual toasts are managed by vue-toast-notification -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import 'vue-toast-notification/dist/theme-default.css'

export default {
  name: 'AppSnackbar',

  data() {
    return {
      timeout: 5000, // 5 seconds default timeout
      toastInstances: {} // Track active toast instances by ID
    }
  },

  computed: {
    ...mapState({
      messages: (state) => state.system.messages
    })
  },

  watch: {
    // Watch for changes in the messages array
    messages: {
      handler(newMessages, oldMessages) {
        // Find new messages that weren't in the old array
        if (oldMessages) {
          const oldIds = oldMessages.map((msg) => msg.id)
          const newMessagesToShow = newMessages.filter(
            (msg) => !oldIds.includes(msg.id)
          )

          // Show each new message as a toast
          newMessagesToShow.forEach((message) => {
            this.showToast(message)
          })
          // this.$store.dispatch('clearMessages')
        } else if (newMessages.length > 0) {
          // First load, show all existing messages
          newMessages.forEach((message) => {
            this.showToast(message)
          })
          // this.$store.dispatch('clearMessages')
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    showToast(message) {
      // Map snackbar color to toast type
      const typeMap = {
        success: 'success',
        error: 'error',
        warning: 'warning',
        info: 'info',
        default: 'default'
      }

      // Determine toast type based on message color
      const type = typeMap[message.color] || 'default'

      // Create toast content
      let content = message.content
      if (message.title) {
        content = `<strong>${message.title}</strong><br>${message.content}`
      }

      // Show the toast
      // Store the instance reference
      this.toastInstances[message.id] = this.$toast.open({
        message: content,
        type: type,
        position: 'top',
        duration: this.timeout,
        dismissible: true,
        onClick: () => this.dismissToast(message.id),
        onDismiss: () => this.onToastDismiss(message.id)
      })
    },

    dismissToast(id) {
      if (this.toastInstances[id]) {
        this.toastInstances[id].dismiss()
      }
    },

    onToastDismiss(id) {
      // Remove message from store
      this.$store.dispatch('popMessage', id)

      // Clean up the instance reference
      delete this.toastInstances[id]
    }
  }
}
</script>
