export default {
  routes: [
    {
      name: 'audit',
      text: 'menu.audit',
      to: { name: 'audit' },
      children: [
        {
          name: 'auditCreate',
          text: 'menu.create',
          to: { name: 'auditCreate' }
        },
        {
          name: 'auditEdit',
          text: '', // audit name
          children: [
            {
              name: 'taskCreate',
              text: 'menu.create'
            },
            {
              name: 'taskEdit',
              text: '', // task name
              stacked: true,
              children: [
                {
                  name: 'taskCreate',
                  text: 'menu.create',
                  is_removed: true
                },
                {
                  name: 'taskEdit',
                  text: '' // task name
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'auditPlan',
      text: 'menu.auditPlan',
      to: { name: 'auditPlan' },
      children: [
        {
          name: 'auditPlanCreate',
          text: 'menu.create'
        },
        {
          name: 'auditPlanEdit',
          text: '', // audit plan name
          children: [
            {
              name: 'taskCreate',
              text: 'menu.create'
            },
            {
              name: 'taskEdit',
              text: '' // task name
            }
          ]
        }
      ]
    },
    {
      name: 'contact',
      text: 'menu.contact',
      to: { name: 'contact' },
      children: [
        {
          name: 'contactCreate',
          text: 'menu.create'
        },
        {
          name: 'contactEdit',
          text: '' // contact first_name last_name
        }
      ]
    },
    {
      name: 'personalDocument',
      text: 'menu.personalDocument',
      to: { name: 'personalDocument' },
      children: [
        {
          name: 'personalDocumentCreate',
          text: 'menu.create'
        },
        {
          name: 'personalDocumentEdit',
          text: '' // personal document name
        }
      ]
    },
    {
      name: 'otherDocument',
      text: 'menu.otherDocument',
      to: { name: 'otherDocument' },
      children: [
        {
          name: 'otherDocumentCreate',
          text: 'menu.create'
        },
        {
          name: 'otherDocumentEdit',
          text: '' // other document name
        }
      ]
    },
    {
      name: 'otherLicense',
      text: 'menu.otherLicense',
      to: { name: 'otherLicense' },
      children: [
        {
          name: 'otherLicenseCreate',
          text: 'menu.create'
        },
        {
          name: 'otherLicenseEdit',
          text: '' // other license name
        }
      ]
    },
    {
      name: 'MemorandumOfAssociation',
      text: 'menu.MemorandumOfAssociation',
      to: { name: 'MemorandumOfAssociation' },
      children: [
        {
          name: 'MemorandumOfAssociationCreate',
          text: 'menu.create'
        },
        {
          name: 'MemorandumOfAssociationEdit',
          text: '' // memorandum of association name
        }
      ]
    },
    {
      name: 'stakeholderDocument',
      text: 'menu.stakeholderDocument',
      to: { name: 'stakeholderDocument' },
      children: [
        {
          name: 'stakeholderDocumentCreate',
          text: 'menu.create'
        },
        {
          name: 'stakeholderDocumentEdit',
          text: '' // stakeholder document name
        }
      ]
    },
    {
      name: 'CompanyCertificate',
      text: 'menu.CompanyCertificate',
      to: { name: 'CompanyCertificate' },
      children: [
        {
          name: 'CompanyCertificateCreate',
          text: 'menu.create'
        },
        {
          name: 'CompanyCertificateEdit',
          text: '' // company certificate name
        }
      ]
    },
    {
      name: 'TaxRegistrationCertificate',
      text: 'menu.TaxRegistrationCertificate',
      to: { name: 'TaxRegistrationCertificate' },
      children: [
        {
          name: 'TaxRegistrationCertificateCreate',
          text: 'menu.create'
        },
        {
          name: 'TaxRegistrationCertificateEdit',
          text: '' // tax registration certificate name
        }
      ]
    },
    {
      name: 'trialBalance',
      text: 'menu.trialBalance',
      to: { name: 'trialBalance' },
      children: [
        {
          name: 'trialBalanceCreate',
          text: 'menu.create'
        },
        {
          name: 'trialBalanceEdit',
          text: '' // trial balance name
        }
      ]
    },
    {
      name: 'asset',
      text: 'menu.asset',
      to: { name: 'asset' },
      children: [
        {
          name: 'assetCreate',
          text: 'menu.create'
        },
        {
          name: 'assetEdit',
          text: '' // asset name
        }
      ]
    },
    {
      name: 'liability',
      text: 'menu.liability',
      to: { name: 'liability' },
      children: [
        {
          name: 'liabilityCreate',
          text: 'menu.create'
        },
        {
          name: 'liabilityEdit',
          text: '' // liability name
        }
      ]
    },
    {
      name: 'equity',
      text: 'menu.equity',
      to: { name: 'equity' },
      children: [
        {
          name: 'equityCreate',
          text: 'menu.create'
        },
        {
          name: 'equityEdit',
          text: '' // equity name
        }
      ]
    },
    {
      name: 'humanAsset',
      text: 'menu.humanAsset',
      to: { name: 'humanAsset' },
      children: [
        {
          name: 'humanAssetCreate',
          text: 'menu.create'
        },
        {
          name: 'humanAssetEdit',
          text: '' // human asset name
        }
      ]
    },
    {
      name: 'lawsuit',
      text: 'menu.lawsuit',
      to: { name: 'lawsuit' },
      children: [
        {
          name: 'lawsuitCreate',
          text: 'menu.create'
        },
        {
          name: 'lawsuitEdit',
          text: '' // lawsuit name
        }
      ]
    },
    {
      name: 'revenue',
      text: 'menu.revenue',
      to: { name: 'revenue' },
      children: [
        {
          name: 'revenueCreate',
          text: 'menu.create'
        },
        {
          name: 'revenueEdit',
          text: '' // revenue name
        }
      ]
    },
    {
      name: 'expense',
      text: 'menu.expense',
      to: { name: 'expense' },
      children: [
        {
          name: 'expenseCreate',
          text: 'menu.create'
        },
        {
          name: 'expenseEdit',
          text: '' // expense name
        }
      ]
    },
    {
      name: 'quotationHeader',
      text: 'menu.quotationHeader',
      to: { name: 'quotationHeader' },
      children: [
        {
          name: 'quotationHeaderCreate',
          text: 'menu.create'
        },
        {
          name: 'quotationHeaderEdit',
          text: '' // quotation header name
        }
      ]
    },
    {
      name: 'businessIndustry',
      text: 'menu.businessIndustry',
      to: { name: 'businessIndustry' },
      children: [
        {
          name: 'businessIndustryCreate',
          text: 'menu.create'
        },
        {
          name: 'businessIndustryEdit',
          text: '' // business industry name
        }
      ]
    },
    {
      name: 'businessCategory',
      text: 'menu.businessCategory',
      to: { name: 'businessCategory' },
      children: [
        {
          name: 'businessCategoryCreate',
          text: 'menu.create'
        },
        {
          name: 'businessCategoryEdit',
          text: '' // business category name
        }
      ]
    },
    {
      name: 'businessType',
      text: 'menu.businessType',
      to: { name: 'businessType' },
      children: [
        {
          name: 'businessTypeCreate',
          text: 'menu.create'
        },
        {
          name: 'businessTypeEdit',
          text: '' // business type name
        }
      ]
    },
    {
      name: 'otherDocumentTemplate',
      text: 'menu.otherDocumentTemplate',
      to: { name: 'otherDocumentTemplate' },
      children: [
        {
          name: 'otherDocumentTemplateCreate',
          text: 'menu.create'
        },
        {
          name: 'otherDocumentTemplateEdit',
          text: '' // other document template name
        }
      ]
    },
    {
      name: 'otherLicenseTemplate',
      text: 'menu.otherLicenseTemplate',
      to: { name: 'otherLicenseTemplate' },
      children: [
        {
          name: 'otherLicenseTemplateCreate',
          text: 'menu.create'
        },
        {
          name: 'otherLicenseTemplateEdit',
          text: '' // other license template name
        }
      ]
    },
    {
      name: 'assetCategory',
      text: 'menu.assetCategory',
      to: { name: 'assetCategory' },
      children: [
        {
          name: 'assetCategoryCreate',
          text: 'menu.create'
        },
        {
          name: 'assetCategoryEdit',
          text: '' // asset category name
        }
      ]
    },
    {
      name: 'assetType',
      text: 'menu.assetType',
      to: { name: 'assetType' },
      children: [
        {
          name: 'assetTypeCreate',
          text: 'menu.create'
        },
        {
          name: 'assetTypeEdit',
          text: '' // asset type name
        }
      ]
    },
    {
      name: 'liabilityCategory',
      text: 'menu.liabilityCategory',
      to: { name: 'liabilityCategory' },
      children: [
        {
          name: 'liabilityCategoryCreate',
          text: 'menu.create'
        },
        {
          name: 'liabilityCategoryEdit',
          text: '' // liability category name
        }
      ]
    },
    {
      name: 'liabilityType',
      text: 'menu.liabilityType',
      to: { name: 'liabilityType' },
      children: [
        {
          name: 'liabilityTypeCreate',
          text: 'menu.create'
        },
        {
          name: 'liabilityTypeEdit',
          text: '' // liability type name
        }
      ]
    },
    {
      name: 'equityType',
      text: 'menu.equityType',
      to: { name: 'equityType' },
      children: [
        {
          name: 'equityTypeCreate',
          text: 'menu.create'
        },
        {
          name: 'equityTypeEdit',
          text: '' // equity type name
        }
      ]
    },
    {
      name: 'equityCategory',
      text: 'menu.equityCategory',
      to: { name: 'equityCategory' },
      children: [
        {
          name: 'equityCategoryCreate',
          text: 'menu.create'
        },
        {
          name: 'equityCategoryEdit',
          text: '' // equity category name
        }
      ]
    },
    {
      name: 'humanAssetCategory',
      text: 'menu.humanAssetCategory',
      to: { name: 'humanAssetCategory' },
      children: [
        {
          name: 'humanAssetCategoryCreate',
          text: 'menu.create'
        },
        {
          name: 'humanAssetCategoryEdit',
          text: '' // human asset category name
        }
      ]
    },
    {
      name: 'humanAssetType',
      text: 'menu.humanAssetType',
      to: { name: 'humanAssetType' },
      children: [
        {
          name: 'humanAssetTypeCreate',
          text: 'menu.create'
        },
        {
          name: 'humanAssetTypeEdit',
          text: '' // human asset type name
        }
      ]
    },
    {
      name: 'lawsuitCategory',
      text: 'menu.lawsuitCategory',
      to: { name: 'lawsuitCategory' },
      children: [
        {
          name: 'lawsuitCategoryCreate',
          text: 'menu.create'
        },
        {
          name: 'lawsuitCategoryEdit',
          text: '' // lawsuit category name
        }
      ]
    },
    {
      name: 'lawsuitType',
      text: 'menu.lawsuitType',
      to: { name: 'lawsuitType' },
      children: [
        {
          name: 'lawsuitTypeCreate',
          text: 'menu.create'
        },
        {
          name: 'lawsuitTypeEdit',
          text: '' // lawsuit type name
        }
      ]
    },
    {
      name: 'revenueCategory',
      text: 'menu.revenueCategory',
      to: { name: 'revenueCategory' },
      children: [
        {
          name: 'revenueCategoryCreate',
          text: 'menu.create'
        },
        {
          name: 'revenueCategoryEdit',
          text: '' // revenue category name
        }
      ]
    },
    {
      name: 'revenueType',
      text: 'menu.revenueType',
      to: { name: 'revenueType' },
      children: [
        {
          name: 'revenueTypeCreate',
          text: 'menu.create'
        },
        {
          name: 'revenueTypeEdit',
          text: '' // revenue type name
        }
      ]
    },
    {
      name: 'expenseCategory',
      text: 'menu.expenseCategory',
      to: { name: 'expenseCategory' },
      children: [
        {
          name: 'expenseCategoryCreate',
          text: 'menu.create'
        },
        {
          name: 'expenseCategoryEdit',
          text: '' // expense category name
        }
      ]
    },
    {
      name: 'expenseType',
      text: 'menu.expenseType',
      to: { name: 'expenseType' },
      children: [
        {
          name: 'expenseTypeCreate',
          text: 'menu.create'
        },
        {
          name: 'expenseTypeEdit',
          text: '' // expense type name
        }
      ]
    },
    {
      name: 'masterAuditProgramTemplate',
      text: 'menu.masterAuditProgramTemplate',
      to: { name: 'masterAuditProgramTemplate' },
      children: [
        {
          name: 'masterAuditProgramTemplateCreate',
          text: 'menu.create'
        },
        {
          name: 'masterAuditProgramTemplateEdit',
          text: '' // master audit program template name
        }
      ]
    },
    {
      name: 'masterTaskTemplate',
      text: 'menu.masterTaskTemplate',
      to: { name: 'masterTaskTemplate' },
      children: [
        {
          name: 'masterTaskTemplateCreate',
          text: 'menu.create'
        },
        {
          name: 'masterTaskTemplateEdit',
          text: '' // master task template name
        }
      ]
    },
    {
      name: 'taskSubCategory',
      text: 'menu.taskSubCategory',
      to: { name: 'taskSubCategory' },
      children: [
        {
          name: 'taskSubCategoryCreate',
          text: 'menu.create'
        },
        {
          name: 'taskSubCategoryEdit',
          text: '' // task sub category name
        }
      ]
    },
    {
      name: 'masterQuestionnaire',
      text: 'menu.masterQuestionnaire',
      to: { name: 'masterQuestionnaire' },
      children: [
        {
          name: 'masterQuestionnaireCreate',
          text: 'menu.create'
        },
        {
          name: 'masterQuestionnaireEdit',
          text: '' // master questionnaire name
        }
      ]
    },
    {
      name: 'questionnaireType',
      text: 'menu.questionnaireType',
      to: { name: 'questionnaireType' },
      children: [
        {
          name: 'questionnaireTypeCreate',
          text: 'menu.create'
        },
        {
          name: 'questionnaireTypeEdit',
          text: '' // questionnaire type name
        }
      ]
    },
    {
      name: 'masterRiskProfileTemplate',
      text: 'menu.masterRiskProfileTemplate',
      to: { name: 'masterRiskProfileTemplate' },
      children: [
        {
          name: 'masterRiskProfileTemplateCreate',
          text: 'menu.create'
        },
        {
          name: 'masterRiskProfileTemplateEdit',
          text: '' // master risk profile template name
        }
      ]
    },
    {
      name: 'riskCategory',
      text: 'menu.riskCategory',
      to: { name: 'riskCategory' },
      children: [
        {
          name: 'riskCategoryCreate',
          text: 'menu.create'
        },
        {
          name: 'riskCategoryEdit',
          text: '' // risk category name
        }
      ]
    },
    {
      name: 'riskType',
      text: 'menu.riskType',
      to: { name: 'riskType' },
      children: [
        {
          name: 'riskTypeCreate',
          text: 'menu.create'
        },
        {
          name: 'riskTypeEdit',
          text: '' // risk type name
        }
      ]
    },
    {
      name: 'masterActivityProfileTemplate',
      text: 'menu.masterActivityProfileTemplate',
      to: { name: 'masterActivityProfileTemplate' },
      children: [
        {
          name: 'masterActivityProfileTemplateCreate',
          text: 'menu.create'
        },
        {
          name: 'masterActivityProfileTemplateEdit',
          text: '' // master activity profile template name
        }
      ]
    },
    {
      name: 'activityType',
      text: 'menu.activityType',
      to: { name: 'activityType' },
      children: [
        {
          name: 'activityTypeCreate',
          text: 'menu.create'
        },
        {
          name: 'activityTypeEdit',
          text: '' // activity type name
        }
      ]
    },
    {
      name: 'objective',
      text: 'menu.objective',
      to: { name: 'objective' },
      children: [
        {
          name: 'objectiveCreate',
          text: 'menu.create'
        },
        {
          name: 'objectiveEdit',
          text: '' // objective name
        }
      ]
    },
    {
      name: 'masterPropertyAndAgreementHeader',
      text: 'menu.masterPropertyAndAgreementHeader',
      to: { name: 'masterPropertyAndAgreementHeader' },
      children: [
        {
          name: 'masterPropertyAndAgreementHeaderCreate',
          text: 'menu.create'
        },
        {
          name: 'masterPropertyAndAgreementHeaderEdit',
          text: '' // master property and agreement header name
        }
      ]
    },
    {
      name: 'masterChartOfAccount',
      text: 'menu.masterChartOfAccount',
      to: { name: 'masterChartOfAccount' },
      children: [
        {
          name: 'masterChartOfAccountCreate',
          text: 'menu.create'
        },
        {
          name: 'masterChartOfAccountEdit',
          text: '' // master chart of account name
        }
      ]
    },
    {
      name: 'customerCompany',
      text: 'menu.customerCompany',
      to: { name: 'customerCompany' },
      children: [
        {
          name: 'customerCompanyCreate',
          text: 'menu.create'
        },
        {
          name: 'customerCompanyEdit',
          text: '' // customer company name
        }
      ]
    },
    {
      name: 'customerCompanyStorage',
      text: 'menu.customerCompanyStorage',
      to: { name: 'customerCompanyStorage' }
    },
    {
      name: 'masterProduct',
      text: 'menu.masterProduct',
      to: { name: 'masterProduct' },
      children: [
        {
          name: 'masterProductCreate',
          text: 'menu.create'
        },
        {
          name: 'masterProductEdit',
          text: '' // master product name
        }
      ]
    },
    {
      name: 'masterWorkingPaperTemplate',
      text: 'menu.masterWorkingPaperTemplate',
      to: { name: 'masterWorkingPaperTemplate' },
      children: [
        {
          name: 'masterWorkingPaperTemplateCreate',
          text: 'menu.create'
        },
        {
          name: 'masterWorkingPaperTemplateEdit',
          text: '' // master working paper template name
        }
      ]
    },
    {
      name: 'masterPosition',
      text: 'menu.masterPosition',
      to: { name: 'masterPosition' },
      children: [
        {
          name: 'masterPositionCreate',
          text: 'menu.create'
        },
        {
          name: 'masterPositionEdit',
          text: '' // master position name
        }
      ]
    },
    {
      name: 'user',
      text: 'menu.user',
      to: { name: 'user' },
      children: [
        {
          name: 'userCreate',
          text: 'menu.create'
        },
        {
          name: 'userEdit',
          text: '' // user name
        }
      ]
    },
    {
      name: 'team',
      text: 'menu.team',
      to: { name: 'team' },
      children: [
        {
          name: 'teamCreate',
          text: 'menu.create'
        },
        {
          name: 'teamEdit',
          text: '' // team name
        }
      ]
    },
    {
      name: 'department',
      text: 'menu.department',
      to: { name: 'Department' },
      children: [
        {
          name: 'departmentCreate',
          text: 'menu.create'
        },
        {
          name: 'departmentEdit',
          text: '' // department name
        }
      ]
    },
    {
      name: 'product',
      text: 'menu.product',
      to: { name: 'product' },
      children: [
        {
          name: 'productCreate',
          text: 'menu.create'
        },
        {
          name: 'productEdit',
          text: '' // product name
        }
      ]
    },
    {
      name: 'businessUnit',
      text: 'menu.businessUnit',
      to: { name: 'businessUnit' },
      children: [
        {
          name: 'businessUnitCreate',
          text: 'menu.create'
        },
        {
          name: 'businessUnitEdit',
          text: '' // business unit name
        }
      ]
    },
    {
      name: 'region',
      text: 'menu.region',
      to: { name: 'region' },
      children: [
        {
          name: 'regionCreate',
          text: 'menu.create'
        },
        {
          name: 'regionEdit',
          text: '' // region name
        }
      ]
    },
    {
      name: 'division',
      text: 'menu.division',
      to: { name: 'division' },
      children: [
        {
          name: 'divisionCreate',
          text: 'menu.create'
        },
        {
          name: 'divisionEdit',
          text: '' // division name
        }
      ]
    },
    {
      name: 'subDivision',
      text: 'menu.subDivision',
      to: { name: 'subDivision' },
      children: [
        {
          name: 'subDivisionCreate',
          text: 'menu.create'
        },
        {
          name: 'subDivisionEdit',
          text: '' // sub division name
        }
      ]
    },
    {
      name: 'assetGroup',
      text: 'menu.assetGroup',
      to: { name: 'assetGroup' },
      children: [
        {
          name: 'assetGroupCreate',
          text: 'menu.create'
        },
        {
          name: 'assetGroupEdit',
          text: '' // asset group name
        }
      ]
    },
    {
      name: 'liabilityGroup',
      text: 'menu.liabilityGroup',
      to: { name: 'liabilityGroup' },
      children: [
        {
          name: 'liabilityGroupCreate',
          text: 'menu.create'
        },
        {
          name: 'liabilityGroupEdit',
          text: '' // liability group name
        }
      ]
    },
    {
      name: 'equityGroup',
      text: 'menu.equityGroup',
      to: { name: 'equityGroup' },
      children: [
        {
          name: 'equityGroupCreate',
          text: 'menu.create'
        },
        {
          name: 'equityGroupEdit',
          text: '' // equity group name
        }
      ]
    },
    {
      name: 'humanAssetGroup',
      text: 'menu.humanAssetGroup',
      to: { name: 'humanAssetGroup' },
      children: [
        {
          name: 'humanAssetGroupCreate',
          text: 'menu.create'
        },
        {
          name: 'humanAssetGroupEdit',
          text: '' // human asset group name
        }
      ]
    },
    {
      name: 'lawsuitGroup',
      text: 'menu.lawsuitGroup',
      to: { name: 'lawsuitGroup' },
      children: [
        {
          name: 'lawsuitGroupCreate',
          text: 'menu.create'
        },
        {
          name: 'lawsuitGroupEdit',
          text: '' // lawsuit group name
        }
      ]
    },
    {
      name: 'revenueGroup',
      text: 'menu.revenueGroup',
      to: { name: 'revenueGroup' },
      children: [
        {
          name: 'revenueGroupCreate',
          text: 'menu.create'
        },
        {
          name: 'revenueGroupEdit',
          text: '' // revenue group name
        }
      ]
    },
    {
      name: 'expenseGroup',
      text: 'menu.expenseGroup',
      to: { name: 'expenseGroup' },
      children: [
        {
          name: 'expenseGroupCreate',
          text: 'menu.create'
        },
        {
          name: 'expenseGroupEdit',
          text: '' // expense group name
        }
      ]
    },
    {
      name: 'auditProgramTemplate',
      text: 'menu.auditProgramTemplate',
      to: { name: 'auditProgramTemplate' },
      children: [
        {
          name: 'auditProgramTemplateCreate',
          text: 'menu.create'
        },
        {
          name: 'auditProgramTemplateEdit',
          text: '' // audit program template name
        }
      ]
    },
    {
      name: 'taskTemplate',
      text: 'menu.taskTemplate',
      to: { name: 'taskTemplate' },
      children: [
        {
          name: 'taskTemplateCreate',
          text: 'menu.create'
        },
        {
          name: 'taskTemplateEdit',
          text: '' // task template name
        }
      ]
    },
    {
      name: 'questionnaire',
      text: 'menu.questionnaire',
      to: { name: 'questionnaire' },
      children: [
        {
          name: 'questionnaireCreate',
          text: 'menu.create'
        },
        {
          name: 'questionnaireEdit',
          text: '' // questionnaire name
        }
      ]
    },
    {
      name: 'riskProfileTemplate',
      text: 'menu.riskProfileTemplate',
      to: { name: 'riskProfileTemplate' },
      children: [
        {
          name: 'riskProfileTemplateCreate',
          text: 'menu.create'
        },
        {
          name: 'riskProfileTemplateEdit',
          text: '' // risk profile template name
        }
      ]
    },
    {
      name: 'activityProfileTemplate',
      text: 'menu.activityProfileTemplate',
      to: { name: 'activityProfileTemplate' },
      children: [
        {
          name: 'activityProfileTemplateCreate',
          text: 'menu.create'
        },
        {
          name: 'activityProfileTemplateEdit',
          text: '' // activity profile template name
        }
      ]
    },
    {
      name: 'workingPaperTemplate',
      text: 'menu.workingPaperTemplate',
      to: { name: 'workingPaperTemplate' },
      children: [
        {
          name: 'workingPaperTemplateCreate',
          text: 'menu.create'
        },
        {
          name: 'workingPaperTemplateEdit',
          text: '' // working paper template name
        }
      ]
    },
    {
      name: 'chartOfAccount',
      text: 'menu.chartOfAccount',
      to: { name: 'chartOfAccount' },
      children: [
        {
          name: 'chartOfAccountCreate',
          text: 'menu.create'
        },
        {
          name: 'chartOfAccountEdit',
          text: '' // chart of account name
        }
      ]
    }
  ]
}
