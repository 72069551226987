export default {
  setLoading(state, value) {
    state.isLoading = value
  },

  // Add a message to the snackbar queue
  pushMessage(state, message) {
    // Create a unique ID for this message to help with removal
    const id =
      new Date().getTime() + Math.random().toString(36).substring(2, 10)
    state.messages.push({
      id,
      title: message.title || null, // Nullable title
      content: message.content,
      multiline: message.multiline || false,
      color: message.color || 'info', // Default color is info
      createdAt: new Date()
    })
  },

  // Remove a message from the queue by its ID
  popMessage(state, id) {
    const index = state.messages.findIndex((message) => message.id === id)
    if (index !== -1) {
      state.messages.splice(index, 1)
    }
  },

  // Remove the oldest message from the queue (FIFO)
  shiftMessage(state) {
    if (state.messages.length > 0) {
      state.messages.shift()
    }
  },

  // Clear all messages
  clearMessages(state) {
    state.messages = []
  }
}
