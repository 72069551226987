const isLoading = (state) => state.system.isLoading
const isLogin = (state) => state.user.isLogin
const payload = (state) => state.user.payload
const exp = (state) => state.user.exp
const user = (state) => state.user.user
const permissions = (state) => state.user.permissions
const breadcrumbs = (state) => state.breadcrumb.breadcrumbs
const messages = (state) => state.system.messages

export default {
  isLoading,
  isLogin,
  payload,
  exp,
  user,
  permissions,
  breadcrumbs,
  messages
}
