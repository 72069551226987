<template>
  <v-navigation-drawer
    app
    :mini-variant="!isSmall && !pin"
    :expand-on-hover="!isSmall && !pin"
    :permanent="!isSmall"
    :temporary="isSmall"
    :value="sidebar"
    @input="(value) => $emit('update:sidebar', value)"
  >
    <router-link class="logo" to="/">
      <v-img contain height="40" :src="require('@assets/roots.png')" />
    </router-link>
    <v-divider />
    <v-list flat dense nav>
      <v-list-item-group color="primary">
        <span v-for="item in validItems(menuItems)" :key="item.name">
          <template v-if="item.children">
            <v-list-group
              no-action
              color="primary"
              :prepend-icon="item.meta.icon"
              :group="item.path"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ menuTitle(item) }}</v-list-item-title>
              </template>
              <template
                v-for="secondItem in validItems(item.children, item.meta?.name)"
              >
                <template v-if="secondItem.children">
                  <v-list-group
                    no-action
                    sub-group
                    color="primary"
                    :key="`${secondItem.path}_${secondItem.name}`"
                  >
                    <template v-slot:activator>
                      <v-list-item-title>{{
                        menuTitle(secondItem)
                      }}</v-list-item-title>
                    </template>
                    <template
                      v-for="thirdItem in validItems(
                        secondItem.children,
                        `${item.meta?.name}_${secondItem.meta?.name}`
                      )"
                    >
                      <template v-if="thirdItem.children">
                        <v-list-group
                          no-action
                          sub-group
                          color="primary"
                          :key="`${thirdItem.path}_${thirdItem.name}`"
                          style="padding-left: 32px"
                        >
                          <template v-slot:activator>
                            <v-list-item-title>{{
                              menuTitle(thirdItem)
                            }}</v-list-item-title>
                          </template>
                          <v-list-item
                            v-for="forthItem in validItems(
                              thirdItem.children,
                              `${item.meta?.name}_${secondItem.meta?.name}_${thirdItem.meta?.name}`
                            )"
                            :key="forthItem.name"
                            :to="`/${item.path}/${secondItem.path}/${thirdItem.path}/${forthItem.path}`"
                          >
                            <v-list-item-title>{{
                              menuTitle(forthItem)
                            }}</v-list-item-title>
                            <v-list-item-icon
                              v-if="forthItem.meta && forthItem.meta.icon"
                            >
                              <v-icon>{{ forthItem.meta.icon }}</v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-group>
                      </template>
                      <template v-else>
                        <v-list-item
                          link
                          :key="thirdItem.name"
                          :to="`/${item.path}/${secondItem.path}/${thirdItem.path}`"
                        >
                          <v-list-item-title>{{
                            menuTitle(thirdItem)
                          }}</v-list-item-title>
                          <v-list-item-icon
                            v-if="thirdItem.meta && thirdItem.meta.icon"
                          >
                            <v-icon>{{ thirdItem.meta.icon }}</v-icon>
                          </v-list-item-icon>
                        </v-list-item>
                      </template>
                    </template>
                  </v-list-group>
                </template>
                <template v-else>
                  <v-list-item
                    link
                    :key="secondItem.name"
                    :to="`/${item.path}/${secondItem.path}`"
                  >
                    <v-list-item-title>{{
                      menuTitle(secondItem)
                    }}</v-list-item-title>
                    <v-list-item-icon
                      v-if="secondItem.meta && secondItem.meta.icon"
                    >
                      <v-icon>{{ secondItem.meta.icon }}</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </template>
            </v-list-group>
          </template>
          <template v-else>
            <v-list-item link :to="`/${item.path}`">
              <v-list-item-icon>
                <v-icon>{{ item.meta.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ menuTitle(item) }}</v-list-item-title>
            </v-list-item>
          </template>
        </span>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'drawer',
  props: {
    sidebar: {
      type: Boolean
    },
    pin: {
      type: Boolean
    },
    menuItems: {
      type: Array
    }
  },
  computed: {
    ...mapGetters(['permissions', 'user']),
    isSmall() {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  async created() {
    if (!this.user) {
      await this.$store.dispatch('updateUserInfo')
    }
  },
  methods: {
    menuTitle(item) {
      return item.meta?.label
        ? this.$t(`menu.${item.meta.label}`)
        : this.$t(`menu.${item.name}`)
    },
    validItems(items, parent = '') {
      return items.filter((item) => {
        const menus = this.user?.menus

        let menuName = item?.meta?.name || ''
        if (parent.length > 0) {
          menuName = `${parent}_${item?.meta?.name || ''}`
        }

        return (
          !item.meta?.hideDrawer &&
          (this.user?.is_superuser ||
            menus?.some((menu) => {
              const nextUnderscoreIndex = menu.indexOf('_', menuName.length)
              const menuToCheck =
                nextUnderscoreIndex !== -1
                  ? menu.substring(0, nextUnderscoreIndex)
                  : menu

              if (
                menuToCheck.startsWith(menuName) &&
                menuName.length > menu.lastIndexOf('_')
              ) {
                return menu.split('_').pop() === item?.meta?.name
              }
              return menuToCheck === menuName
            }))
        )
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
