import AuthLayout from '@layouts/auth/AuthLayout'
import CmsLayout from '@layouts/cms/CmsLayout'
import Empty from '@layouts/Empty.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { afterRouter, beforeRouter } from './middleware'

Vue.use(VueRouter)

const lazyImport = (path) => () => import(`@/views/${path}`)

const routes = [
  {
    path: '/',
    component: CmsLayout,
    meta: {
      isDrawer: true
    },
    children: [
      {
        name: 'home',
        path: '',
        component: lazyImport('Home'),
        meta: {
          icon: 'mdi-home',
          isHideBreadcrumb: true,
          permissions: [],
          name: 'home'
        }
      },
      {
        path: 'contact',
        component: Empty,
        meta: {
          label: 'contact',
          icon: 'mdi-account-box',
          childName: {
            list: { name: 'contact' },
            create: { name: 'contactCreate' },
            edit: { name: 'contactEdit' }
          },
          name: 'contact'
        },
        children: [
          {
            name: 'contact',
            path: 'contact',
            component: lazyImport('contacts/contact/Contact'),
            meta: {
              name: 'contact'
            }
          },
          {
            name: 'contactCreate',
            path: 'contact/create',
            component: lazyImport('contacts/contact/ContactForm'),
            meta: {
              hideDrawer: true
            }
          },
          {
            name: 'contactEdit',
            path: 'contact/record/:id',
            component: lazyImport('contacts/contact/ContactForm'),
            meta: {
              hideDrawer: true
            }
          },
          {
            name: 'documents',
            path: 'documents',
            component: Empty,
            meta: {
              name: 'documents'
            },
            children: [
              {
                path: 'personal-document',
                component: Empty,
                meta: {
                  label: 'personal',
                  childName: {
                    list: { name: 'personalDocument' },
                    create: { name: 'personalDocumentCreate' },
                    edit: { name: 'personalDocumentEdit' }
                  },
                  name: 'personal'
                },
                children: [
                  {
                    name: 'personalDocument',
                    path: '',
                    component: lazyImport(
                      'contacts/document/personal-document/PersonalDocument'
                    ),
                    meta: {
                      name: 'personaldocument'
                    }
                  },
                  {
                    name: 'personalDocumentCreate',
                    path: 'create',
                    component: lazyImport(
                      'contacts/document/personal-document/PersonalDocumentForm'
                    ),
                    meta: {
                      hideDrawer: true
                    }
                  },
                  {
                    name: 'personalDocumentEdit',
                    path: 'record/:id',
                    component: lazyImport(
                      'contacts/document/personal-document/PersonalDocumentForm'
                    ),
                    meta: {
                      hideDrawer: true
                    }
                  }
                ]
              },
              {
                name: 'document',
                path: 'document',
                component: Empty,
                meta: {
                  name: 'document'
                },
                children: [
                  {
                    path: 'other-document',
                    component: Empty,
                    meta: {
                      label: 'otherDocument',
                      childName: {
                        list: { name: 'otherDocument' },
                        create: { name: 'otherDocumentCreate' },
                        edit: { name: 'otherDocumentEdit' }
                      },
                      name: 'otherdocument'
                    },
                    children: [
                      {
                        name: 'otherDocument',
                        path: '/',
                        component: lazyImport(
                          'contacts/document/other-document/otherDocument'
                        )
                      },
                      {
                        name: 'otherDocumentCreate',
                        path: 'create',
                        component: lazyImport(
                          'contacts/document/other-document/otherDocumentForm'
                        ),
                        meta: {
                          hideDrawer: true
                        }
                      },
                      {
                        name: 'otherDocumentEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'contacts/document/other-document/otherDocumentForm'
                        ),
                        meta: {
                          hideDrawer: true
                        }
                      }
                    ]
                  },
                  {
                    path: 'other-license',
                    component: Empty,
                    meta: {
                      label: 'otherLicense',
                      childName: {
                        list: { name: 'otherLicense' },
                        create: { name: 'otherLicenseCreate' },
                        edit: { name: 'otherLicenseEdit' }
                      },
                      name: 'otherlicense'
                    },
                    children: [
                      {
                        name: 'otherLicense',
                        path: '/',
                        component: lazyImport(
                          'contacts/document/other-license/otherLicense'
                        )
                      },
                      {
                        name: 'otherLicenseCreate',
                        path: 'create',
                        component: lazyImport(
                          'contacts/document/other-license/otherLicenseForm'
                        ),
                        meta: {
                          hideDrawer: true
                        }
                      },
                      {
                        name: 'otherLicenseEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'contacts/document/other-license/otherLicenseForm'
                        ),
                        meta: {
                          hideDrawer: true
                        }
                      }
                    ]
                  }
                ]
              },
              {
                name: 'companyDocument',
                path: 'companyDocument',
                component: Empty,
                meta: {
                  name: 'companydocument'
                },
                children: [
                  {
                    path: 'MemorandumOfAssociation',
                    component: Empty,
                    meta: {
                      label: 'MemorandumOfAssociation',
                      childName: {
                        list: { name: 'MemorandumOfAssociation' },
                        create: { name: 'MemorandumOfAssociationCreate' },
                        edit: { name: 'MemorandumOfAssociationEdit' }
                      },
                      name: 'memorandumofassociation'
                    },
                    children: [
                      {
                        name: 'MemorandumOfAssociation',
                        path: '',
                        component: lazyImport(
                          'contacts/document/MemorandumOfAssociation'
                        )
                      },
                      {
                        name: 'MemorandumOfAssociationCreate',
                        path: 'create',
                        component: lazyImport(
                          'contacts/document/MemorandumOfAssociationForm'
                        )
                      },
                      {
                        name: 'MemorandumOfAssociationEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'contacts/document/MemorandumOfAssociationForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'stakeholderDocument',
                    component: Empty,
                    meta: {
                      label: 'stakeholderDocument',
                      childName: {
                        list: { name: 'stakeholderDocument' },
                        create: { name: 'stakeholderDocumentCreate' },
                        edit: { name: 'stakeholderDocumentEdit' }
                      },
                      name: 'stakeholderdocument'
                    },
                    children: [
                      {
                        name: 'stakeholderDocument',
                        path: '',
                        component: lazyImport(
                          'contacts/document/StakeholderDocument'
                        )
                      },
                      {
                        name: 'stakeholderDocumentCreate',
                        path: 'create',
                        component: lazyImport(
                          'contacts/document/StakeholderDocumentForm'
                        )
                      },
                      {
                        name: 'stakeholderDocumentEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'contacts/document/StakeholderDocumentForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'company-certificate',
                    component: Empty,
                    meta: {
                      label: 'CompanyCertificate',
                      childName: {
                        list: { name: 'CompanyCertificate' },
                        create: { name: 'CompanyCertificateCreate' },
                        edit: { name: 'CompanyCertificateEdit' }
                      },
                      name: 'companycertificate'
                    },
                    children: [
                      {
                        name: 'CompanyCertificate',
                        path: '/',
                        component: lazyImport(
                          'contacts/document/company-certificate/CompanyCertificate'
                        )
                      },
                      {
                        name: 'CompanyCertificateCreate',
                        path: 'create',
                        component: lazyImport(
                          'contacts/document/company-certificate/CompanyCertificateForm'
                        ),
                        meta: {
                          hideDrawer: true
                        }
                      },
                      {
                        name: 'CompanyCertificateEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'contacts/document/company-certificate/CompanyCertificateForm'
                        ),
                        meta: {
                          hideDrawer: true
                        }
                      }
                    ]
                  },
                  {
                    path: 'TaxRegistrationCertificate',
                    component: Empty,
                    meta: {
                      label: 'TaxRegistrationCertificate',
                      childName: {
                        list: { name: 'TaxRegistrationCertificate' },
                        create: { name: 'TaxRegistrationCertificateCreate' },
                        edit: { name: 'TaxRegistrationCertificateEdit' }
                      },
                      name: 'taxregistrationcertificate'
                    },
                    children: [
                      {
                        name: 'TaxRegistrationCertificate',
                        path: '',
                        component: lazyImport(
                          'contacts/document/taxregis-certificate/TaxRegistrationCertificate'
                        )
                      },
                      {
                        name: 'TaxRegistrationCertificateCreate',
                        path: 'create',
                        component: lazyImport(
                          'contacts/document/taxregis-certificate/TaxRegistrationCertificateForm'
                        )
                      },
                      {
                        name: 'TaxRegistrationCertificateEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'contacts/document/taxregis-certificate/TaxRegistrationCertificateForm'
                        )
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            name: 'contactDraft',
            path: 'new',
            component: lazyImport('contacts/contactDraft'),
            meta: {
              hideDrawer: true
            }
          }
        ]
      },
      {
        path: 'audit',
        component: Empty,
        meta: {
          label: 'audit',
          icon: 'mdi-book',
          childName: {
            list: { name: 'trialBalance' },
            create: { name: 'trialBalanceCreate' },
            edit: { name: 'trialBalanceEdit' }
          },
          name: 'audit'
        },
        children: [
          {
            name: 'trialBalance',
            path: 'trial-balance',
            component: lazyImport('audit/trial-balance/TrialBalance'),
            meta: {
              name: 'trialbalance'
            }
          },
          {
            name: 'trialBalanceCreate',
            path: 'trial-balance/create',
            component: lazyImport('audit/trial-balance/TrialBalanceForm'),
            meta: {
              hideDrawer: true
            }
          },
          {
            name: 'trialBalanceEdit',
            path: 'trial-balance/record/:id',
            component: lazyImport('audit/trial-balance/TrialBalanceForm'),
            meta: {
              hideDrawer: true
            }
          },
          {
            name: 'auditPlan',
            path: 'audit-plan-header',
            component: lazyImport('audit/audit-plan/AuditPlan'),
            meta: {
              name: 'auditplan'
            }
          },
          {
            name: 'auditPlanCreate',
            path: 'audit-plan-header/create',
            component: lazyImport('audit/audit-plan/AuditPlanForm'),
            meta: {
              hideDrawer: true,
              childName: {
                edit: { name: 'auditPlanEdit' }
              }
            }
          },
          {
            name: 'auditPlanEdit',
            path: 'audit-plan-header/record/:id',
            component: lazyImport('audit/audit-plan/AuditPlanForm'),
            meta: {
              hideDrawer: true
            }
          },
          {
            name: 'audit',
            path: 'audit-header',
            component: lazyImport('audit/audit/Audit'),
            meta: {
              name: 'audit'
            }
          },
          {
            name: 'auditCreate',
            path: 'audit-header/create',
            component: lazyImport('audit/audit/AuditForm'),
            meta: {
              hideDrawer: true
            }
          },
          {
            name: 'auditEdit',
            path: 'audit-header/record/:id',
            component: lazyImport('audit/audit/AuditForm'),
            meta: {
              hideDrawer: true
            }
          },
          {
            name: 'property-and-agreement',
            path: 'property-and-agreement',
            component: Empty,
            meta: {
              label: 'propertyAndAgreement',
              name: 'propertyandagreement'
            },
            children: [
              {
                name: 'asset',
                path: 'asset',
                component: lazyImport('audit/asset/Asset'),
                meta: {
                  name: 'asset'
                }
              },
              {
                name: 'assetCreate',
                path: 'asset/create',
                component: lazyImport('audit/asset/AssetForm'),
                meta: {
                  hideDrawer: true,
                  childName: {
                    edit: { name: 'assetEdit' },
                    list: { name: 'asset' }
                  }
                }
              },
              {
                name: 'assetEdit',
                path: 'asset/record/:id',
                component: lazyImport('audit/asset/AssetForm'),
                meta: {
                  hideDrawer: true,
                  childName: {
                    edit: { name: 'assetEdit' }
                  }
                }
              },
              {
                name: 'liability',
                path: 'liability',
                component: lazyImport('audit/liability/Liability'),
                meta: {
                  name: 'liability'
                }
              },
              {
                name: 'liabilityCreate',
                path: 'liability/create',
                component: lazyImport('audit/liability/LiabilityForm'),
                meta: {
                  hideDrawer: true,
                  childName: {
                    edit: { name: 'liabilityEdit' },
                    list: { name: 'liability' }
                  }
                }
              },
              {
                name: 'liabilityEdit',
                path: 'liability/record/:id',
                component: lazyImport('audit/liability/LiabilityForm'),
                meta: {
                  hideDrawer: true,
                  childName: {
                    edit: { name: 'liabilityEdit' }
                  }
                }
              },
              {
                name: 'equity',
                path: 'equity',
                component: lazyImport('audit/equity/Equity'),
                meta: {
                  name: 'equity'
                }
              },
              {
                name: 'equityCreate',
                path: 'equity/create',
                component: lazyImport('audit/equity/EquityForm'),
                meta: {
                  hideDrawer: true,
                  childName: {
                    edit: { name: 'equityEdit' },
                    list: { name: 'equity' }
                  }
                }
              },
              {
                name: 'equityEdit',
                path: 'equity/record/:id',
                component: lazyImport('audit/equity/EquityForm'),
                meta: {
                  hideDrawer: true,
                  childName: {
                    edit: { name: 'equityEdit' }
                  }
                }
              },
              {
                name: 'humanAsset',
                path: 'human-asset',
                component: lazyImport('audit/human-asset/HumanAsset'),
                meta: {
                  name: 'humanasset'
                }
              },
              {
                name: 'humanAssetCreate',
                path: 'human-asset/create',
                component: lazyImport('audit/human-asset/HumanAssetForm'),
                meta: {
                  hideDrawer: true,
                  childName: {
                    edit: { name: 'humanAssetEdit' },
                    list: { name: 'humanAsset' }
                  }
                }
              },
              {
                name: 'humanAssetEdit',
                path: 'human-asset/record/:id',
                component: lazyImport('audit/human-asset/HumanAssetForm'),
                meta: {
                  hideDrawer: true,
                  childName: {
                    edit: { name: 'humanAssetEdit' }
                  }
                }
              },
              {
                name: 'lawsuit',
                path: 'lawsuit',
                component: lazyImport('audit/lawsuit/Lawsuit'),
                meta: {
                  name: 'lawsuit'
                }
              },
              {
                name: 'lawsuitCreate',
                path: 'lawsuit/create',
                component: lazyImport('audit/lawsuit/LawsuitForm'),
                meta: {
                  hideDrawer: true,
                  childName: {
                    edit: { name: 'lawsuitEdit' },
                    list: { name: 'lawsuit' }
                  }
                }
              },
              {
                name: 'lawsuitEdit',
                path: 'lawsuit/record/:id',
                component: lazyImport('audit/lawsuit/LawsuitForm'),
                meta: {
                  hideDrawer: true,
                  childName: {
                    edit: { name: 'lawsuitEdit' }
                  }
                }
              },
              {
                name: 'revenue',
                path: 'revenue',
                component: lazyImport('audit/revenue/Revenue'),
                meta: {
                  name: 'revenue'
                }
              },
              {
                name: 'revenueCreate',
                path: 'revenue/create',
                component: lazyImport('audit/revenue/RevenueForm'),
                meta: {
                  hideDrawer: true,
                  childName: {
                    edit: { name: 'revenueEdit' },
                    list: { name: 'revenue' }
                  }
                }
              },
              {
                name: 'revenueEdit',
                path: 'revenue/record/:id',
                component: lazyImport('audit/revenue/RevenueForm'),
                meta: {
                  hideDrawer: true,
                  childName: {
                    edit: { name: 'revenueEdit' }
                  }
                }
              },
              {
                name: 'expense',
                path: 'expense',
                component: lazyImport('audit/expense/Expense'),
                meta: {
                  name: 'expense'
                }
              },
              {
                name: 'expenseCreate',
                path: 'expense/create',
                component: lazyImport('audit/expense/ExpenseForm'),
                meta: {
                  hideDrawer: true,
                  childName: {
                    edit: { name: 'expenseEdit' },
                    list: { name: 'expense' }
                  }
                }
              },
              {
                name: 'expenseEdit',
                path: 'expense/record/:id',
                component: lazyImport('audit/expense/ExpenseForm'),
                meta: {
                  hideDrawer: true,
                  childName: {
                    edit: { name: 'expenseEdit' }
                  }
                }
              }
            ]
          }
          // {
          //   name: 'trialBalance',
          //   path: 'trial-balance',
          //   component: lazyImport('audit/trial-balance/TrialBalance')
          // },
          // {
          //   name: 'trialBalanceCreate',
          //   path: 'trial-balance/create',
          //   component: lazyImport('audit/trial-balance/TrialBalanceForm'),
          //   meta: {
          //     hideDrawer: true
          //   }
          // },
          // {
          //   name: 'trialBalanceEdit',
          //   path: 'trial-balance/record/:id',
          //   component: lazyImport('audit/trial-balance/TrialBalanceForm'),
          //   meta: {
          //     hideDrawer: true
          //   }
          // },
        ]
      },
      {
        path: 'sale',
        component: Empty,
        meta: {
          label: 'sale',
          icon: 'mdi-cart-plus'
        },
        children: [
          {
            name: 'quotation',
            path: 'quotation',
            component: Empty,
            meta: {
              label: 'quotationHeader',
              childName: {
                list: { name: 'quotationHeader' },
                create: { name: 'quotationHeaderCreate' },
                edit: { name: 'quotationHeaderEdit' }
              }
            },
            children: [
              {
                name: 'quotationHeader',
                path: '',
                component: lazyImport('sale/quotation/QuotationHeader')
              },
              {
                name: 'quotationHeaderCreate',
                path: 'create',
                component: lazyImport('sale/quotation/QuotationHeaderForm'),
                meta: {
                  hideDrawer: true
                }
              },
              {
                name: 'quotationHeaderEdit',
                path: 'record/:id',
                component: lazyImport('sale/quotation/QuotationHeaderForm'),
                meta: {
                  hideDrawer: true
                }
              }
            ]
          }
        ]
      },
      {
        path: 'setting',
        component: Empty,
        meta: {
          label: 'setting',
          icon: 'mdi-cog',
          name: 'setting'
        },
        children: [
          {
            name: 'plenty',
            path: 'configuration',
            component: Empty,
            meta: {
              name: 'plenty'
            },
            children: [
              {
                name: 'contact',
                path: 'configuration',
                component: Empty,
                meta: {
                  name: 'contact'
                },
                children: [
                  {
                    path: 'business-industry',
                    component: Empty,
                    meta: {
                      label: 'businessIndustry',
                      childName: {
                        list: { name: 'businessIndustry' },
                        create: { name: 'businessIndustryCreate' },
                        edit: { name: 'businessIndustryEdit' }
                      },
                      name: 'businessindustry'
                    },
                    children: [
                      {
                        name: 'businessIndustry',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/business-industry/BusinessIndustry'
                        )
                      },
                      {
                        name: 'businessIndustryCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/business-industry/BusinessIndustryForm'
                        )
                      },
                      {
                        name: 'businessIndustryEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/business-industry/BusinessIndustryForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'business-category',
                    component: Empty,
                    meta: {
                      label: 'businessCategory',
                      childName: {
                        list: { name: 'businessCategory' },
                        create: { name: 'businessCategoryCreate' },
                        edit: { name: 'businessCategoryEdit' }
                      },
                      name: 'businesscategory'
                    },
                    children: [
                      {
                        name: 'businessCategory',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/business-category/BusinessCategory'
                        )
                      },
                      {
                        name: 'businessCategoryCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/business-category/BusinessCategoryForm'
                        )
                      },
                      {
                        name: 'businessCategoryEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/business-category/BusinessCategoryForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'business-type',
                    component: Empty,
                    meta: {
                      label: 'businessType',
                      childName: {
                        list: { name: 'businessType' },
                        create: { name: 'businessTypeCreate' },
                        edit: { name: 'businessTypeEdit' }
                      },
                      name: 'businesstype'
                    },
                    children: [
                      {
                        name: 'businessType',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/business-type/BusinessType'
                        )
                      },
                      {
                        name: 'businessTypeCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/business-type/BusinessTypeForm'
                        )
                      },
                      {
                        name: 'businessTypeEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/business-type/BusinessTypeForm'
                        )
                      }
                    ]
                  }
                ]
              },
              {
                name: 'document',
                path: 'document',
                component: Empty,
                meta: {
                  name: 'document'
                },
                children: [
                  {
                    path: 'other-document-template',
                    component: Empty,
                    meta: {
                      label: 'otherDocumentTemplate',
                      childName: {
                        list: { name: 'otherDocumentTemplate' },
                        create: { name: 'otherDocumentTemplateCreate' },
                        edit: { name: 'otherDocumentTemplateEdit' }
                      },
                      name: 'otherdocumenttemplate'
                    },
                    children: [
                      {
                        name: 'otherDocumentTemplate',
                        path: '',
                        component: lazyImport(
                          'contacts/document/otherdocumentTemplate'
                        )
                      },
                      {
                        name: 'otherDocumentTemplateCreate',
                        path: 'create',
                        component: lazyImport(
                          'contacts/document/otherdocumentTemplateForm'
                        ),
                        meta: {
                          hideDrawer: true
                        }
                      },
                      {
                        name: 'otherDocumentTemplateEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'contacts/document/otherdocumentTemplateForm'
                        ),
                        meta: {
                          hideDrawer: true
                        }
                      }
                    ]
                  },
                  {
                    path: 'other-license',
                    component: Empty,
                    meta: {
                      label: 'otherLicenseTemplate',
                      childName: {
                        list: { name: 'otherLicenseTemplate' },
                        create: { name: 'otherLicenseTemplateCreate' },
                        edit: { name: 'otherLicenseTemplateEdit' }
                      },
                      name: 'otherlicensetemplate'
                    },
                    children: [
                      {
                        name: 'otherLicenseTemplate',
                        path: '',
                        component: lazyImport(
                          'contacts/document/otherLicenseTemplate'
                        )
                      },
                      {
                        name: 'otherLicenseTemplateCreate',
                        path: 'create',
                        component: lazyImport(
                          'contacts/document/otherLicenseTemplateForm'
                        )
                      },
                      {
                        name: 'otherLicenseTemplateEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'contacts/document/otherLicenseTemplateForm'
                        )
                      }
                    ]
                  }
                ]
              },
              {
                name: 'propertyAndAgreement',
                path: 'propertyAndAgreement',
                component: Empty,
                meta: {
                  name: 'propertyandagreement'
                },
                children: [
                  {
                    path: 'asset-category',
                    component: Empty,
                    meta: {
                      label: 'assetCategory',
                      childName: {
                        list: { name: 'assetCategory' },
                        create: { name: 'assetCategoryCreate' },
                        edit: { name: 'assetCategoryEdit' }
                      },
                      name: 'assetcategory'
                    },
                    children: [
                      {
                        name: 'assetCategory',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/asset-category/AssetCategory'
                        )
                      },
                      {
                        name: 'assetCategoryCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/asset-category/AssetCategoryForm'
                        )
                      },
                      {
                        name: 'assetCategoryEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/asset-category/AssetCategoryForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'asset-type',
                    component: Empty,
                    meta: {
                      label: 'assetType',
                      childName: {
                        list: { name: 'assetType' },
                        create: { name: 'assetTypeCreate' },
                        edit: { name: 'assetTypeEdit' }
                      },
                      name: 'assettype'
                    },
                    children: [
                      {
                        name: 'assetType',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/asset-type/AssetType'
                        )
                      },
                      {
                        name: 'assetTypeCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/asset-type/AssetTypeForm'
                        )
                      },
                      {
                        name: 'assetTypeEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/asset-type/AssetTypeForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'liability-category',
                    component: Empty,
                    meta: {
                      label: 'liabilityCategory',
                      childName: {
                        list: { name: 'liabilityCategory' },
                        create: { name: 'liabilityCategoryCreate' },
                        edit: { name: 'liabilityCategoryEdit' }
                      },
                      name: 'liabilitycategory'
                    },
                    children: [
                      {
                        name: 'liabilityCategory',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/liability-category/LiabilityCategory'
                        )
                      },
                      {
                        name: 'liabilityCategoryCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/liability-category/LiabilityCategoryForm'
                        )
                      },
                      {
                        name: 'liabilityCategoryEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/liability-category/LiabilityCategoryForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'liability-type',
                    component: Empty,
                    meta: {
                      label: 'liabilityType',
                      childName: {
                        list: { name: 'liabilityType' },
                        create: { name: 'liabilityTypeCreate' },
                        edit: { name: 'liabilityTypeEdit' }
                      },
                      name: 'liabilitytype'
                    },
                    children: [
                      {
                        name: 'liabilityType',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/liability-type/LiabilityType'
                        )
                      },
                      {
                        name: 'liabilityTypeCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/liability-type/LiabilityTypeForm'
                        )
                      },
                      {
                        name: 'liabilityTypeEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/liability-type/LiabilityTypeForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'equity-category',
                    component: Empty,
                    meta: {
                      label: 'equityCategory',
                      childName: {
                        list: { name: 'equityCategory' },
                        create: { name: 'equityCategoryCreate' },
                        edit: { name: 'equityCategoryEdit' }
                      },
                      name: 'equitycategory'
                    },
                    children: [
                      {
                        name: 'equityCategory',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/equity-category/EquityCategory'
                        )
                      },
                      {
                        name: 'equityCategoryCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/equity-category/EquityCategoryForm'
                        )
                      },
                      {
                        name: 'equityCategoryEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/equity-category/EquityCategoryForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'equity-type',
                    component: Empty,
                    meta: {
                      label: 'equityType',
                      childName: {
                        list: { name: 'equityType' },
                        create: { name: 'equityTypeCreate' },
                        edit: { name: 'equityTypeEdit' }
                      },
                      name: 'equitytype'
                    },
                    children: [
                      {
                        name: 'equityType',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/equity-type/EquityType'
                        )
                      },
                      {
                        name: 'equityTypeCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/equity-type/EquityTypeForm'
                        )
                      },
                      {
                        name: 'equityTypeEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/equity-type/EquityTypeForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'human-asset-category',
                    component: Empty,
                    meta: {
                      label: 'humanAssetCategory',
                      childName: {
                        list: { name: 'humanAssetCategory' },
                        create: { name: 'humanAssetCategoryCreate' },
                        edit: { name: 'humanAssetCategoryEdit' }
                      },
                      name: 'humanassetcategory'
                    },
                    children: [
                      {
                        name: 'humanAssetCategory',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/human-asset-category/HumanAssetCategory'
                        )
                      },
                      {
                        name: 'humanAssetCategoryCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/human-asset-category/HumanAssetCategoryForm'
                        )
                      },
                      {
                        name: 'humanAssetCategoryEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/human-asset-category/HumanAssetCategoryForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'human-asset-type',
                    component: Empty,
                    meta: {
                      label: 'humanAssetType',
                      childName: {
                        list: { name: 'humanAssetType' },
                        create: { name: 'humanAssetTypeCreate' },
                        edit: { name: 'humanAssetTypeEdit' }
                      },
                      name: 'humanassettype'
                    },
                    children: [
                      {
                        name: 'humanAssetType',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/human-asset-type/HumanAssetType'
                        )
                      },
                      {
                        name: 'humanAssetTypeCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/human-asset-type/HumanAssetTypeForm'
                        )
                      },
                      {
                        name: 'humanAssetTypeEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/human-asset-type/HumanAssetTypeForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'lawsuit-category',
                    component: Empty,
                    meta: {
                      label: 'lawsuitCategory',
                      childName: {
                        list: { name: 'lawsuitCategory' },
                        create: { name: 'lawsuitCategoryCreate' },
                        edit: { name: 'lawsuitCategoryEdit' }
                      },
                      name: 'lawsuitcategory'
                    },
                    children: [
                      {
                        name: 'lawsuitCategory',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/lawsuit-category/LawsuitCategory'
                        )
                      },
                      {
                        name: 'lawsuitCategoryCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/lawsuit-category/LawsuitCategoryForm'
                        )
                      },
                      {
                        name: 'lawsuitCategoryEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/lawsuit-category/LawsuitCategoryForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'lawsuit-type',
                    component: Empty,
                    meta: {
                      label: 'lawsuitType',
                      childName: {
                        list: { name: 'lawsuitType' },
                        create: { name: 'lawsuitTypeCreate' },
                        edit: { name: 'lawsuitTypeEdit' }
                      },
                      name: 'lawsuittype'
                    },
                    children: [
                      {
                        name: 'lawsuitType',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/lawsuit-type/LawsuitType'
                        )
                      },
                      {
                        name: 'lawsuitTypeCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/lawsuit-type/LawsuitTypeForm'
                        )
                      },
                      {
                        name: 'lawsuitTypeEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/lawsuit-type/LawsuitTypeForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'revenue-category',
                    component: Empty,
                    meta: {
                      label: 'revenueCategory',
                      childName: {
                        list: { name: 'revenueCategory' },
                        create: { name: 'revenueCategoryCreate' },
                        edit: { name: 'revenueCategoryEdit' }
                      },
                      name: 'revenuecategory'
                    },
                    children: [
                      {
                        name: 'revenueCategory',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/revenue-category/RevenueCategory'
                        )
                      },
                      {
                        name: 'revenueCategoryCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/revenue-category/RevenueCategoryForm'
                        )
                      },
                      {
                        name: 'revenueCategoryEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/revenue-category/RevenueCategoryForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'revenue-type',
                    component: Empty,
                    meta: {
                      label: 'revenueType',
                      childName: {
                        list: { name: 'revenueType' },
                        create: { name: 'revenueTypeCreate' },
                        edit: { name: 'revenueTypeEdit' }
                      },
                      name: 'revenuetype'
                    },
                    children: [
                      {
                        name: 'revenueType',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/revenue-type/RevenueType'
                        )
                      },
                      {
                        name: 'revenueTypeCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/revenue-type/RevenueTypeForm'
                        )
                      },
                      {
                        name: 'revenueTypeEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/revenue-type/RevenueTypeForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'expense-category',
                    component: Empty,
                    meta: {
                      label: 'expenseCategory',
                      childName: {
                        list: { name: 'expenseCategory' },
                        create: { name: 'expenseCategoryCreate' },
                        edit: { name: 'expenseCategoryEdit' }
                      },
                      name: 'expensecategory'
                    },
                    children: [
                      {
                        name: 'expenseCategory',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/expense-category/ExpenseCategory'
                        )
                      },
                      {
                        name: 'expenseCategoryCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/expense-category/ExpenseCategoryForm'
                        )
                      },
                      {
                        name: 'expenseCategoryEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/expense-category/ExpenseCategoryForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'expense-type',
                    component: Empty,
                    meta: {
                      label: 'expenseType',
                      childName: {
                        list: { name: 'expenseType' },
                        create: { name: 'expenseTypeCreate' },
                        edit: { name: 'expenseTypeEdit' }
                      },
                      name: 'expensetype'
                    },
                    children: [
                      {
                        name: 'expenseType',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/expense-type/ExpenseType'
                        )
                      },
                      {
                        name: 'expenseTypeCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/expense-type/ExpenseTypeForm'
                        )
                      },
                      {
                        name: 'expenseTypeEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/expense-type/ExpenseTypeForm'
                        )
                      }
                    ]
                  }
                ]
              },
              {
                name: 'audit',
                path: 'configuration',
                component: Empty,
                meta: {
                  name: 'audit'
                },
                children: [
                  {
                    path: 'master-program-template',
                    component: Empty,
                    meta: {
                      label: 'masterAuditProgramTemplate',
                      childName: {
                        list: { name: 'masterAuditProgramTemplate' },
                        create: { name: 'masterAuditProgramTemplateCreate' },
                        edit: { name: 'masterAuditProgramTemplateEdit' }
                      },
                      name: 'masterauditprogramtemplate'
                    },
                    children: [
                      {
                        name: 'masterAuditProgramTemplate',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/audit-program-template/MasterAuditProgram'
                        )
                      },
                      {
                        name: 'masterAuditProgramTemplateCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/audit-program-template/MasterAuditProgramForm'
                        )
                      },
                      {
                        name: 'masterAuditProgramTemplateEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/audit-program-template/MasterAuditProgramForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'masterTaskTemplate',
                    component: Empty,
                    meta: {
                      label: 'masterTaskTemplate',
                      childName: {
                        list: { name: 'masterTaskTemplate' },
                        create: { name: 'masterTaskTemplateCreate' },
                        edit: { name: 'masterTaskTemplateEdit' }
                      },
                      name: 'mastertasktemplate'
                    },
                    children: [
                      {
                        name: 'masterTaskTemplate',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/task/MasterTaskTemplate'
                        )
                      },
                      {
                        name: 'masterTaskTemplateCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/task/MasterTaskTemplateForm'
                        )
                      },
                      {
                        name: 'masterTaskTemplateEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/task/MasterTaskTemplateForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'taskSubCategory',
                    component: Empty,
                    meta: {
                      label: 'taskSubCategory',
                      childName: {
                        list: { name: 'taskSubCategory' },
                        create: { name: 'taskSubCategoryCreate' },
                        edit: { name: 'taskSubCategoryEdit' }
                      },
                      name: 'tasksubcategory'
                    },
                    children: [
                      {
                        name: 'taskSubCategory',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/task/TaskSubCategory'
                        )
                      },
                      {
                        name: 'taskSubCategoryCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/task/TaskSubCategoryForm'
                        )
                      },
                      {
                        name: 'taskSubCategoryEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/task/TaskSubCategoryForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'master-questionnaire-header',
                    component: Empty,
                    meta: {
                      label: 'masterQuestionnaire',
                      childName: {
                        list: { name: 'masterQuestionnaire' },
                        create: { name: 'masterQuestionnaireCreate' },
                        edit: { name: 'masterQuestionnaireEdit' }
                      },
                      name: 'masterquestionnaire'
                    },
                    children: [
                      {
                        name: 'masterQuestionnaire',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/questionnaire/MasterQuestionnaire'
                        )
                      },
                      {
                        name: 'masterQuestionnaireCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/questionnaire/MasterQuestionnaireForm'
                        )
                      },
                      {
                        name: 'masterQuestionnaireEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/questionnaire/MasterQuestionnaireForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'questionnaire-type',
                    component: Empty,
                    meta: {
                      label: 'questionnaireType',
                      childName: {
                        list: { name: 'questionnaireType' },
                        create: { name: 'questionnaireTypeCreate' },
                        edit: { name: 'questionnaireTypeEdit' }
                      },
                      name: 'questionnairetype'
                    },
                    children: [
                      {
                        name: 'questionnaireType',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/questionnaire/QuestionnaireType'
                        )
                      },
                      {
                        name: 'questionnaireTypeCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/questionnaire/QuestionnaireTypeForm'
                        )
                      },
                      {
                        name: 'questionnaireTypeEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/questionnaire/QuestionnaireTypeForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'master-risk-profile-template-header',
                    component: Empty,
                    meta: {
                      label: 'masterRiskProfileTemplate',
                      childName: {
                        list: { name: 'masterRiskProfileTemplate' },
                        create: { name: 'masterRiskProfileTemplateCreate' },
                        edit: { name: 'masterRiskProfileTemplateEdit' }
                      },
                      name: 'masterriskprofiletemplateheader'
                    },
                    children: [
                      {
                        name: 'masterRiskProfileTemplate',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/risk-profile-template/MasterRiskProfileTemplate'
                        )
                      },
                      {
                        name: 'masterRiskProfileTemplateCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/risk-profile-template/MasterRiskProfileTemplateForm'
                        )
                      },
                      {
                        name: 'masterRiskProfileTemplateEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/risk-profile-template/MasterRiskProfileTemplateForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'risk-category',
                    component: Empty,
                    meta: {
                      label: 'riskCategory',
                      childName: {
                        list: { name: 'riskCategory' },
                        create: { name: 'riskCategoryCreate' },
                        edit: { name: 'riskCategoryEdit' }
                      },
                      name: 'riskcategory'
                    },
                    children: [
                      {
                        name: 'riskCategory',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/risk-category/RiskCategory'
                        )
                      },
                      {
                        name: 'riskCategoryCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/risk-category/RiskCategoryForm'
                        )
                      },
                      {
                        name: 'riskCategoryEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/risk-category/RiskCategoryForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'risk-type',
                    component: Empty,
                    meta: {
                      label: 'riskType',
                      childName: {
                        list: { name: 'riskType' },
                        create: { name: 'riskTypeCreate' },
                        edit: { name: 'riskTypeEdit' }
                      },
                      name: 'risktype'
                    },
                    children: [
                      {
                        name: 'riskType',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/risk-type/RiskType'
                        )
                      },
                      {
                        name: 'riskTypeCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/risk-type/RiskTypeForm'
                        )
                      },
                      {
                        name: 'riskTypeEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/risk-type/RiskTypeForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'masterActivityProfileTemplate',
                    component: Empty,
                    meta: {
                      label: 'masterActivityProfileTemplate',
                      childName: {
                        list: { name: 'masterActivityProfileTemplate' },
                        create: { name: 'masterActivityProfileTemplateCreate' },
                        edit: { name: 'masterActivityProfileTemplateEdit' }
                      },
                      name: 'masteractivityprofiletemplate'
                    },
                    children: [
                      {
                        name: 'masterActivityProfileTemplate',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/activity-profile-template/MasterActivityProfileTemplate'
                        )
                      },
                      {
                        name: 'masterActivityProfileTemplateCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/activity-profile-template/MasterActivityProfileTemplateForm'
                        )
                      },
                      {
                        name: 'masterActivityProfileTemplateEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/activity-profile-template/MasterActivityProfileTemplateForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'activityType',
                    component: Empty,
                    meta: {
                      label: 'activityType',
                      childName: {
                        list: { name: 'activityType' },
                        create: { name: 'activityTypeCreate' },
                        edit: { name: 'activityTypeEdit' }
                      },
                      name: 'activitytype'
                    },
                    children: [
                      {
                        name: 'activityType',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/activity-type/ActivityType'
                        )
                      },
                      {
                        name: 'activityTypeCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/activity-type/ActivityTypeForm'
                        )
                      },
                      {
                        name: 'activityTypeEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/activity-type/ActivityTypeForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'objective',
                    component: Empty,
                    meta: {
                      label: 'objective',
                      childName: {
                        list: { name: 'objective' },
                        create: { name: 'objectiveCreate' },
                        edit: { name: 'objectiveEdit' }
                      },
                      name: 'objective'
                    },
                    children: [
                      {
                        name: 'objective',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/objective/Objective'
                        )
                      },
                      {
                        name: 'objectiveCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/objective/ObjectiveForm'
                        )
                      },
                      {
                        name: 'objectiveEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/objective/ObjectiveForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'masterPropertyAndAgreementHeader',
                    component: Empty,
                    meta: {
                      label: 'masterPropertyAndAgreementHeader',
                      childName: {
                        list: { name: 'masterPropertyAndAgreementHeader' },
                        create: {
                          name: 'masterPropertyAndAgreementHeaderCreate'
                        },
                        edit: { name: 'masterPropertyAndAgreementHeaderEdit' }
                      },
                      name: 'masterpropertyandagreementheader'
                    },
                    children: [
                      {
                        name: 'masterPropertyAndAgreementHeader',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/master-tab/propertyAndAgreement'
                        )
                      },
                      {
                        name: 'masterPropertyAndAgreementHeaderCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/master-tab/propertyAndAgreementForm'
                        )
                      },
                      {
                        name: 'masterPropertyAndAgreementHeaderEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/master-tab/propertyAndAgreementForm'
                        )
                      }
                    ]
                  }
                ]
              },
              {
                path: 'master-chart-of-account',
                component: Empty,
                meta: {
                  label: 'masterChartOfAccount',
                  childName: {
                    list: { name: 'masterChartOfAccount' },
                    create: { name: 'masterChartOfAccountCreate' },
                    edit: { name: 'masterChartOfAccountEdit' }
                  },
                  name: 'masterchartofaccount'
                },
                children: [
                  {
                    name: 'masterChartOfAccount',
                    path: '',
                    component: lazyImport(
                      'setting/configuration/master-chart-of-account/MasterChartOfAccount'
                    ),
                    meta: {
                      name: 'masterchartofaccount'
                    }
                  },
                  {
                    name: 'masterChartOfAccountCreate',
                    path: 'create',
                    component: lazyImport(
                      'setting/configuration/master-chart-of-account/MasterChartOfAccountForm'
                    ),
                    meta: {
                      hideDrawer: true
                    }
                  },
                  {
                    name: 'masterChartOfAccountEdit',
                    path: 'record/:id',
                    component: lazyImport(
                      'setting/configuration/master-chart-of-account/MasterChartOfAccountForm'
                    ),
                    meta: {
                      hideDrawer: true
                    }
                  }
                ]
              },
              {
                name: 'customer',
                path: 'customer',
                component: Empty,
                meta: {
                  name: 'customer'
                },
                children: [
                  {
                    path: 'customer-company',
                    component: Empty,
                    meta: {
                      label: 'customerCompany',
                      childName: {
                        list: { name: 'customerCompany' },
                        create: { name: 'customerCompanyCreate' },
                        edit: { name: 'customerCompanyEdit' }
                      },
                      name: 'customercompany'
                    },
                    children: [
                      {
                        name: 'customerCompany',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/customer-company/CustomerCompany'
                        )
                      },
                      {
                        name: 'customerCompanyCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/customer-company/CustomerCompanyForm'
                        )
                      },
                      {
                        name: 'customerCompanyEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/customer-company/CustomerCompanyForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'customer-company=storage',
                    component: Empty,
                    meta: {
                      label: 'customerCompanyStorage',
                      childName: {
                        list: { name: 'customerCompanyStorage' }
                      },
                      name: 'customercompanystorage'
                    },
                    children: [
                      {
                        name: 'customerCompanyStorage',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/customer-company-storage/CustomerCompanyStorage'
                        )
                      }
                    ]
                  },
                  {
                    path: 'master-product',
                    component: Empty,
                    meta: {
                      label: 'masterProduct',
                      childName: {
                        list: { name: 'masterProduct' },
                        create: { name: 'masterProductCreate' },
                        edit: { name: 'masterProductEdit' }
                      },
                      name: 'masterproduct'
                    },
                    children: [
                      {
                        name: 'masterProduct',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/master-product/MasterProduct'
                        )
                      },
                      {
                        name: 'masterProductCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/master-product/MasterProductForm'
                        )
                      },
                      {
                        name: 'masterProductEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/master-product/MasterProductForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'masterWorkingPaperTemplate',
                    component: Empty,
                    meta: {
                      label: 'masterWorkingPaperTemplate',
                      childName: {
                        list: { name: 'masterWorkingPaperTemplate' },
                        create: { name: 'masterWorkingPaperTemplateCreate' },
                        edit: { name: 'masterWorkingPaperTemplateEdit' }
                      },
                      name: 'masterworkingpapertemplate'
                    },
                    children: [
                      {
                        name: 'masterWorkingPaperTemplate',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/working-paper-template/MasterWorkingTemplate'
                        )
                      },
                      {
                        name: 'masterWorkingPaperTemplateCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/working-paper-template/MasterWorkingTemplateForm'
                        )
                      },
                      {
                        name: 'masterWorkingPaperTemplateEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/working-paper-template/MasterWorkingTemplateForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'master-position',
                    component: Empty,
                    meta: {
                      label: 'masterPosition',
                      childName: {
                        list: { name: 'masterPosition' },
                        create: { name: 'masterPositionCreate' },
                        edit: { name: 'masterPositionEdit' }
                      },
                      name: 'masterposition'
                    },
                    children: [
                      {
                        name: 'masterPosition',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/master-position/MasterPosition'
                        )
                      },
                      {
                        name: 'masterPositionCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/master-position/MasterPositionForm'
                        )
                      },
                      {
                        name: 'masterPositionEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/master-position/MasterPositionForm'
                        )
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            name: 'customer',
            path: 'configuration',
            component: Empty,
            meta: {
              name: 'customer'
            },
            children: [
              {
                name: 'auditFirm',
                path: 'configuration',
                component: Empty,
                meta: {
                  name: 'auditfirm'
                },
                children: [
                  {
                    path: 'user',
                    component: Empty,
                    meta: {
                      label: 'user',
                      childName: {
                        list: { name: 'user' },
                        create: { name: 'userCreate' },
                        edit: { name: 'userEdit' }
                      },
                      name: 'user'
                    },
                    children: [
                      {
                        name: 'user',
                        path: '',
                        component: lazyImport('setting/configuration/user/User')
                      },
                      {
                        name: 'userCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/user/UserForm'
                        ),
                        meta: {
                          hideDrawer: true
                        }
                      },
                      {
                        name: 'userEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/user/UserForm'
                        ),
                        meta: {
                          hideDrawer: true
                        }
                      }
                    ]
                  },
                  {
                    path: 'team-header',
                    component: Empty,
                    meta: {
                      label: 'team',
                      childName: {
                        list: { name: 'team' },
                        create: { name: 'teamCreate' },
                        edit: { name: 'teamEdit' }
                      },
                      name: 'team'
                    },
                    children: [
                      {
                        name: 'team',
                        path: '',
                        component: lazyImport('setting/configuration/team/Team')
                      },
                      {
                        name: 'teamCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/team/TeamForm'
                        )
                      },
                      {
                        name: 'teamEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/team/TeamForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'department',
                    component: Empty,
                    meta: {
                      label: 'department',
                      childName: {
                        list: { name: 'Department' },
                        create: { name: 'departmentCreate' },
                        edit: { name: 'departmentEdit' }
                      },
                      name: 'department'
                    },
                    children: [
                      {
                        name: 'Department',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/department/Department'
                        )
                      },
                      {
                        name: 'departmentCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/department/DepartmentForm'
                        )
                      },
                      {
                        name: 'departmentEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/department/DepartmentForm'
                        ),
                        meta: {
                          hideDrawer: true
                        }
                      }
                    ]
                  },
                  {
                    path: 'product',
                    component: Empty,
                    meta: {
                      label: 'product',
                      childName: {
                        list: { name: 'product' },
                        create: { name: 'productCreate' },
                        edit: { name: 'productEdit' }
                      },
                      name: 'product'
                    },
                    children: [
                      {
                        name: 'product',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/product/Product'
                        )
                      },
                      {
                        name: 'productCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/product/ProductForm'
                        )
                      },
                      {
                        name: 'productEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/product/ProductForm'
                        )
                      }
                    ]
                  }
                ]
              },
              {
                name: 'contact',
                path: 'configuration',
                component: Empty,
                meta: {
                  name: 'contact'
                },
                children: [
                  {
                    path: 'businessUnit',
                    component: Empty,
                    meta: {
                      label: 'businessUnit',
                      childName: {
                        list: { name: 'businessUnit' },
                        create: { name: 'businessUnitCreate' },
                        edit: { name: 'businessUnitEdit' }
                      },
                      name: 'businessunit'
                    },
                    children: [
                      {
                        name: 'businessUnit',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/business-unit/BusinessUnit'
                        )
                      },
                      {
                        name: 'businessUnitCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/business-unit/BusinessUnitForm'
                        )
                      },
                      {
                        name: 'businessUnitEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/business-unit/BusinessUnitForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'region',
                    component: Empty,
                    meta: {
                      label: 'region',
                      childName: {
                        list: { name: 'region' },
                        create: { name: 'regionCreate' },
                        edit: { name: 'regionEdit' }
                      },
                      name: 'region'
                    },
                    children: [
                      {
                        name: 'region',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/region/Region'
                        )
                      },
                      {
                        name: 'regionCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/region/RegionForm'
                        )
                      },
                      {
                        name: 'regionEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/region/RegionForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'division',
                    component: Empty,
                    meta: {
                      label: 'division',
                      childName: {
                        list: { name: 'division' },
                        create: { name: 'divisionCreate' },
                        edit: { name: 'divisionEdit' }
                      },
                      name: 'division'
                    },
                    children: [
                      {
                        name: 'division',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/division/Division'
                        )
                      },
                      {
                        name: 'divisionCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/division/DivisionForm'
                        )
                      },
                      {
                        name: 'divisionEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/division/DivisionForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'subDivision',
                    component: Empty,
                    meta: {
                      label: 'subDivision',
                      childName: {
                        list: { name: 'subDivision' },
                        create: { name: 'subDivisionCreate' },
                        edit: { name: 'subDivisionEdit' }
                      },
                      name: 'subdivision'
                    },
                    children: [
                      {
                        name: 'subDivision',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/sub-division/SubDivision'
                        )
                      },
                      {
                        name: 'subDivisionCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/sub-division/SubDivisionForm'
                        )
                      },
                      {
                        name: 'subDivisionEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/sub-division/SubDivisionForm'
                        )
                      }
                    ]
                  }
                ]
              },
              {
                name: 'propertyAndAgreement',
                path: 'propertyAndAgreement',
                component: Empty,
                meta: {
                  name: 'propertyandagreement'
                },
                children: [
                  {
                    path: 'asset-group',
                    component: Empty,
                    meta: {
                      label: 'assetGroup',
                      childName: {
                        list: { name: 'assetGroup' },
                        create: { name: 'assetGroupCreate' },
                        edit: { name: 'assetGroupEdit' }
                      },
                      name: 'assetgroup'
                    },
                    children: [
                      {
                        name: 'assetGroup',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/asset-group/AssetGroup'
                        )
                      },
                      {
                        name: 'assetGroupCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/asset-group/AssetGroupForm'
                        )
                      },
                      {
                        name: 'assetGroupEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/asset-group/AssetGroupForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'liability-group',
                    component: Empty,
                    meta: {
                      label: 'liabilityGroup',
                      childName: {
                        list: { name: 'liabilityGroup' },
                        create: { name: 'liabilityGroupCreate' },
                        edit: { name: 'liabilityGroupEdit' }
                      },
                      name: 'liabilitygroup'
                    },
                    children: [
                      {
                        name: 'liabilityGroup',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/liability-group/LiabilityGroup'
                        )
                      },
                      {
                        name: 'liabilityGroupCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/liability-group/LiabilityGroupForm'
                        )
                      },
                      {
                        name: 'liabilityGroupEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/liability-group/LiabilityGroupForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'equity-group',
                    component: Empty,
                    meta: {
                      label: 'equityGroup',
                      childName: {
                        list: { name: 'equityGroup' },
                        create: { name: 'equityGroupCreate' },
                        edit: { name: 'equityGroupEdit' }
                      },
                      name: 'equitygroup'
                    },
                    children: [
                      {
                        name: 'equityGroup',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/equity-group/EquityGroup'
                        )
                      },
                      {
                        name: 'equityGroupCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/equity-group/EquityGroupForm'
                        )
                      },
                      {
                        name: 'equityGroupEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/equity-group/EquityGroupForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'human-asset-group',
                    component: Empty,
                    meta: {
                      label: 'humanAssetGroup',
                      childName: {
                        list: { name: 'humanAssetGroup' },
                        create: { name: 'humanAssetGroupCreate' },
                        edit: { name: 'humanAssetGroupEdit' }
                      },
                      name: 'humanassetgroup'
                    },
                    children: [
                      {
                        name: 'humanAssetGroup',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/human-asset-group/HumanAssetGroup'
                        )
                      },
                      {
                        name: 'humanAssetGroupCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/human-asset-group/HumanAssetGroupForm'
                        )
                      },
                      {
                        name: 'humanAssetGroupEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/human-asset-group/HumanAssetGroupForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'lawsuit-group',
                    component: Empty,
                    meta: {
                      label: 'lawsuitGroup',
                      childName: {
                        list: { name: 'lawsuitGroup' },
                        create: { name: 'lawsuitGroupCreate' },
                        edit: { name: 'lawsuitGroupEdit' }
                      },
                      name: 'lawsuitgroup'
                    },
                    children: [
                      {
                        name: 'lawsuitGroup',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/lawsuit-group/LawsuitGroup'
                        )
                      },
                      {
                        name: 'lawsuitGroupCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/lawsuit-group/LawsuitGroupForm'
                        )
                      },
                      {
                        name: 'lawsuitGroupEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/lawsuit-group/LawsuitGroupForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'revenue-group',
                    component: Empty,
                    meta: {
                      label: 'revenueGroup',
                      childName: {
                        list: { name: 'revenueGroup' },
                        create: { name: 'revenueGroupCreate' },
                        edit: { name: 'revenueGroupEdit' }
                      },
                      name: 'revenuegroup'
                    },
                    children: [
                      {
                        name: 'revenueGroup',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/revenue-group/RevenueGroup'
                        )
                      },
                      {
                        name: 'revenueGroupCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/revenue-group/RevenueGroupForm'
                        )
                      },
                      {
                        name: 'revenueGroupEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/revenue-group/RevenueGroupForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'expense-group',
                    component: Empty,
                    meta: {
                      label: 'expenseGroup',
                      childName: {
                        list: { name: 'expenseGroup' },
                        create: { name: 'expenseGroupCreate' },
                        edit: { name: 'expenseGroupEdit' }
                      },
                      name: 'expensegroup'
                    },
                    children: [
                      {
                        name: 'expenseGroup',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/expense-group/ExpenseGroup'
                        )
                      },
                      {
                        name: 'expenseGroupCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/expense-group/ExpenseGroupForm'
                        )
                      },
                      {
                        name: 'expenseGroupEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/expense-group/ExpenseGroupForm'
                        )
                      }
                    ]
                  }
                ]
              },
              {
                name: 'audit',
                path: 'configuration',
                component: Empty,
                meta: {
                  name: 'audit'
                },
                children: [
                  {
                    path: 'program-template',
                    component: Empty,
                    meta: {
                      label: 'auditProgramTemplate',
                      childName: {
                        list: { name: 'auditProgramTemplate' },
                        create: { name: 'auditProgramTemplateCreate' },
                        edit: { name: 'auditProgramTemplateEdit' }
                      },
                      name: 'auditprogramtemplate'
                    },
                    children: [
                      {
                        name: 'auditProgramTemplate',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/audit-program-template/AuditProgram'
                        )
                      },
                      {
                        name: 'auditProgramTemplateCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/audit-program-template/AuditProgramForm'
                        )
                      },
                      {
                        name: 'auditProgramTemplateEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/audit-program-template/AuditProgramForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'taskTemplate',
                    component: Empty,
                    meta: {
                      label: 'taskTemplate',
                      childName: {
                        list: { name: 'taskTemplate' },
                        create: { name: 'taskTemplateCreate' },
                        edit: { name: 'taskTemplateEdit' }
                      },
                      name: 'tasktemplate'
                    },
                    children: [
                      {
                        name: 'taskTemplate',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/task/TaskTemplate'
                        )
                      },
                      {
                        name: 'taskTemplateCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/task/TaskTemplateForm'
                        )
                      },
                      {
                        name: 'taskTemplateEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/task/TaskTemplateForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'questionnaire-header',
                    component: Empty,
                    meta: {
                      label: 'questionnaire',
                      childName: {
                        list: { name: 'questionnaire' },
                        create: { name: 'questionnaireCreate' },
                        edit: { name: 'questionnaireEdit' }
                      },
                      name: 'questionnaire'
                    },
                    children: [
                      {
                        name: 'questionnaire',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/questionnaire/Questionnaire'
                        )
                      },
                      {
                        name: 'questionnaireCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/questionnaire/QuestionnaireForm'
                        )
                      },
                      {
                        name: 'questionnaireEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/questionnaire/QuestionnaireForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'risk-profile-template-header',
                    component: Empty,
                    meta: {
                      label: 'riskProfileTemplate',
                      childName: {
                        list: { name: 'riskProfileTemplate' },
                        create: { name: 'riskProfileTemplateCreate' },
                        edit: { name: 'riskProfileTemplateEdit' }
                      },
                      name: 'riskprofiletemplate'
                    },
                    children: [
                      {
                        name: 'riskProfileTemplate',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/risk-profile-template/RiskProfileTemplate'
                        )
                      },
                      {
                        name: 'riskProfileTemplateCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/risk-profile-template/RiskProfileTemplateForm'
                        )
                      },
                      {
                        name: 'riskProfileTemplateEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/risk-profile-template/RiskProfileTemplateForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'activityProfileTemplate',
                    component: Empty,
                    meta: {
                      label: 'activityProfileTemplate',
                      childName: {
                        list: { name: 'activityProfileTemplate' },
                        create: { name: 'activityProfileTemplateCreate' },
                        edit: { name: 'activityProfileTemplateEdit' }
                      },
                      name: 'activityprofiletemplate'
                    },
                    children: [
                      {
                        name: 'activityProfileTemplate',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/activity-profile-template/ActivityProfileTemplate'
                        )
                      },
                      {
                        name: 'activityProfileTemplateCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/activity-profile-template/ActivityProfileTemplateForm'
                        )
                      },
                      {
                        name: 'activityProfileTemplateEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/activity-profile-template/ActivityProfileTemplateForm'
                        )
                      }
                    ]
                  },
                  {
                    path: 'workingPaperTemplate',
                    component: Empty,
                    meta: {
                      label: 'workingPaperTemplate',
                      childName: {
                        list: { name: 'workingPaperTemplate' },
                        create: { name: 'workingPaperTemplateCreate' },
                        edit: { name: 'workingPaperTemplateEdit' }
                      },
                      name: 'workingpapertemplate'
                    },
                    children: [
                      {
                        name: 'workingPaperTemplate',
                        path: '',
                        component: lazyImport(
                          'setting/configuration/working-paper-template/WorkingPaperTemplate'
                        )
                      },
                      {
                        name: 'workingPaperTemplateCreate',
                        path: 'create',
                        component: lazyImport(
                          'setting/configuration/working-paper-template/WorkingPaperTemplateForm'
                        )
                      },
                      {
                        name: 'workingPaperTemplateEdit',
                        path: 'record/:id',
                        component: lazyImport(
                          'setting/configuration/working-paper-template/WorkingPaperTemplateForm'
                        )
                      }
                    ]
                  }
                ]
              },
              {
                path: 'chart-of-account',
                component: Empty,
                meta: {
                  label: 'chartOfAccount',
                  childName: {
                    list: { name: 'chartOfAccount' },
                    create: { name: 'chartOfAccountCreate' },
                    edit: { name: 'chartOfAccountEdit' }
                  },
                  name: 'chartofaccount'
                },
                children: [
                  {
                    name: 'chartOfAccount',
                    path: '',
                    component: lazyImport(
                      'setting/configuration/chart-of-account/ChartOfAccount'
                    ),
                    meta: {
                      name: 'chartofaccount'
                    }
                  },
                  {
                    name: 'chartOfAccountCreate',
                    path: 'create',
                    component: lazyImport(
                      'setting/configuration/chart-of-account/ChartOfAccountForm'
                    ),
                    meta: {
                      hideDrawer: true
                    }
                  },
                  {
                    name: 'chartOfAccountEdit',
                    path: 'record/:id',
                    component: lazyImport(
                      'setting/configuration/chart-of-account/ChartOfAccountForm'
                    ),
                    meta: {
                      hideDrawer: true
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'task',
        component: Empty,
        meta: {
          label: 'task',
          childName: {
            create: { name: 'taskCreate' },
            edit: { name: 'taskEdit' }
          },
          hideDrawer: true
        },
        children: [
          {
            name: 'taskCreate',
            path: 'create',
            component: lazyImport('audit/task/TaskForm')
          },
          {
            name: 'taskEdit',
            path: 'record/:id',
            component: lazyImport('audit/task/TaskForm')
          }
        ]
      }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: lazyImport('auth/Login')
      },
      {
        name: 'forget-password',
        path: 'forget-password',
        component: lazyImport('auth/ForgetPassword')
      }
    ]
  },
  {
    path: '/logout',
    redirect: { name: 'login' }
  },
  {
    path: '*',
    redirect: { name: 'home' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// before router change
router.beforeEach(beforeRouter)

// after router change
router.afterEach(afterRouter)

export default router
