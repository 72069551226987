export default {
  showLoading(context) {
    context.commit('setLoading', true)
  },
  hideLoading(context) {
    context.commit('setLoading', false)
  },

  /**
   * Push a new snackbar message to the queue
   * @param {Object} context - Vuex action context
   * @param {Object} message - Message object
   * @param {string|null} message.title - Optional title for the message
   * @param {string} message.content - Content of the message
   * @param {boolean} message.multiline - Whether the message should be multiline
   * @param {string} message.color - Color of the snackbar (info, success, warning, error, etc.)
   */
  pushMessage(context, message) {
    if (!message || !message.content) {
      console.error('Message must contain content')
      return
    }

    context.commit('pushMessage', message)

    // Optional: Return the created message for potential future reference
    return context.state.messages[context.state.messages.length - 1]
  },

  /**
   * Remove a specific message by ID
   */
  popMessage(context, id) {
    context.commit('popMessage', id)
  },

  /**
   * Remove the oldest message (FIFO)
   */
  shiftMessage(context) {
    context.commit('shiftMessage')
  },

  /**
   * Helper method to push success message
   */
  pushSuccess(context, message) {
    context.dispatch('pushMessage', {
      content: typeof message === 'string' ? message : message.content,
      title: typeof message === 'string' ? null : message.title,
      multiline:
        typeof message === 'string' ? false : message.multiline || false,
      color: 'success'
    })
  },

  /**
   * Helper method to push error message
   */
  pushError(context, message) {
    context.dispatch('pushMessage', {
      content: typeof message === 'string' ? message : message.content,
      title: typeof message === 'string' ? null : message.title,
      multiline:
        typeof message === 'string' ? false : message.multiline || false,
      color: 'error'
    })
  },

  /**
   * Helper method to push warning message
   */
  pushWarning(context, message) {
    context.dispatch('pushMessage', {
      content: typeof message === 'string' ? message : message.content,
      title: typeof message === 'string' ? null : message.title,
      multiline:
        typeof message === 'string' ? false : message.multiline || false,
      color: 'warning'
    })
  },

  /**
   * Clear all messages
   */
  clearMessages(context) {
    context.commit('clearMessages')
  }
}
