var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{attrs:{"app":"","mini-variant":!_vm.isSmall && !_vm.pin,"expand-on-hover":!_vm.isSmall && !_vm.pin,"permanent":!_vm.isSmall,"temporary":_vm.isSmall,"value":_vm.sidebar},on:{"input":(value) => _vm.$emit('update:sidebar', value)}},[_c('router-link',{staticClass:"logo",attrs:{"to":"/"}},[_c('v-img',{attrs:{"contain":"","height":"40","src":require('@assets/roots.png')}})],1),_c('v-divider'),_c('v-list',{attrs:{"flat":"","dense":"","nav":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.validItems(_vm.menuItems)),function(item){return _c('span',{key:item.name},[(item.children)?[_c('v-list-group',{attrs:{"no-action":"","color":"primary","prepend-icon":item.meta.icon,"group":item.path},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(_vm.menuTitle(item)))])]},proxy:true}],null,true)},[_vm._l((_vm.validItems(item.children, item.meta?.name)),function(secondItem){return [(secondItem.children)?[_c('v-list-group',{key:`${secondItem.path}_${secondItem.name}`,attrs:{"no-action":"","sub-group":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(_vm.menuTitle(secondItem)))])]},proxy:true}],null,true)},[_vm._l((_vm.validItems(
                      secondItem.children,
                      `${item.meta?.name}_${secondItem.meta?.name}`
                    )),function(thirdItem){return [(thirdItem.children)?[_c('v-list-group',{key:`${thirdItem.path}_${thirdItem.name}`,staticStyle:{"padding-left":"32px"},attrs:{"no-action":"","sub-group":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(_vm.menuTitle(thirdItem)))])]},proxy:true}],null,true)},_vm._l((_vm.validItems(
                            thirdItem.children,
                            `${item.meta?.name}_${secondItem.meta?.name}_${thirdItem.meta?.name}`
                          )),function(forthItem){return _c('v-list-item',{key:forthItem.name,attrs:{"to":`/${item.path}/${secondItem.path}/${thirdItem.path}/${forthItem.path}`}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.menuTitle(forthItem)))]),(forthItem.meta && forthItem.meta.icon)?_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(forthItem.meta.icon))])],1):_vm._e()],1)}),1)]:[_c('v-list-item',{key:thirdItem.name,attrs:{"link":"","to":`/${item.path}/${secondItem.path}/${thirdItem.path}`}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.menuTitle(thirdItem)))]),(thirdItem.meta && thirdItem.meta.icon)?_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(thirdItem.meta.icon))])],1):_vm._e()],1)]]})],2)]:[_c('v-list-item',{key:secondItem.name,attrs:{"link":"","to":`/${item.path}/${secondItem.path}`}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.menuTitle(secondItem)))]),(secondItem.meta && secondItem.meta.icon)?_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(secondItem.meta.icon))])],1):_vm._e()],1)]]})],2)]:[_c('v-list-item',{attrs:{"link":"","to":`/${item.path}`}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.meta.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.menuTitle(item)))])],1)]],2)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }